import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './EnquiriesPage.scss';
import { useNavigate } from 'react-router-dom';
import EnquiryListItem from '../../components/Enquiry/EnquiryListItem';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import CustomButton from '../../components/UI/CustomButton';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import Colors from '../../constants/Colors';
import { useGetAllReferralsByCustomerId } from '../../hooks/gql/query/getAll/useGetAllReferralsByCustomerId';

const GET_ALL_CUSTOMERS_REFS_BY_ID = gql`
  query GetAllReferralsByCustomerId($input: CustomerReferralsInput!) {
    getAllReferralsByCustomerId(input: $input) {
      _id
      status
      trip_info {
        pickup_datetime
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        return_datetime
        passenger_count
        travelling_reason
        tripType
        vehicle_type
      }
      proposals {
        _id
      }
    }
  }
`;

const EnquiriesPage: React.FC<{}> = (props) => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();
  const [page_number, setPage_number] = useState(1);
  const [sortOption, setSortOption] = useState(
    'accepting_offer-offer_accepted'
  );

  const {
    data: enquiries,
    error: enquiriesError,
    loading: enquiriesLoading,
    refetch: enquiriesRefectch,
  } = useGetAllReferralsByCustomerId(GET_ALL_CUSTOMERS_REFS_BY_ID, {
    customer_id: user._id || '64f8267c7ed1f6aa0166bde1',
    page: page_number,
    item_per_page: 20,
    status: sortOption,
  });

  return (
    <>
      <div className='sorting_wrapper'>
        <p>Sort by</p>
        <FormControl style={{ marginLeft: 20 }}>
          <Select
            id='sort-simple-select'
            value={sortOption}
            onChange={(event) => {
              setSortOption(event.target.value as string);
              enquiriesRefectch();
            }}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}>
            <MenuItem value={'accepting_offer-offer_accepted'}>
              All Enquiries
            </MenuItem>
            <MenuItem value={'accepting_offer'}>In Progress</MenuItem>
            <MenuItem value={'offer_accepted'}>Completed</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className='enquiries_wrapper'>
        {isAuthenticated ? (
          <>
            {enquiriesLoading && <LoadingSpinner />}
            {enquiries?.length === 0 && (
              <div className='no_enquiries'>
                <p>Oops! No Enquiries Found...</p>
              </div>
            )}
            {enquiries &&
              enquiries.length > 0 &&
              enquiries.map((enq) => {
                return (
                  <EnquiryListItem
                    key={enq._id}
                    _id={enq._id}
                    trip_info={enq.trip_info}
                    status={enq.status}
                    proposalsCount={enq.proposals?.length || 0}
                    onClick={(_id: string) => {
                      navigate(`/enquiries/${_id}`);
                    }}
                  />
                );
              })}
          </>
        ) : (
          <>
            <div className='no_enquiries'>
              <p>
                Initiate Booking/Provide information in{' '}
                <strong
                  onClick={() => navigate('/')}
                  style={{
                    cursor: 'pointer',
                    color: Colors.primaryColorLight,
                  }}>
                  Home Page
                </strong>{' '}
                to See this Page!
              </p>
            </div>
          </>
        )}
      </div>

      <div className='enquiries_action_wrapper'>
        <CustomButton
          title='Load More'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            enquiriesRefectch();
          }}
          isLoading={enquiriesLoading}
        />
      </div>
    </>
  );
};

export default EnquiriesPage;
