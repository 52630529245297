import React, { useEffect, useRef, useState } from 'react';
import './AdminSupportPage.scss';
import { Avatar, CircularProgress, IconButton } from '@mui/material';
import Colors from '../../constants/Colors';
import { formatMessages } from '../../utils/functions';
import SendIcon from '@mui/icons-material/Send';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { motion } from 'framer-motion';
import moment from 'moment';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useAddCuMessage } from '../../hooks/gql/mutation/create/useAddCuMessage';
import { useUpdateCuMessage } from '../../hooks/gql/mutation/update/useUpdateCuMessage';
import { useGetCuMessageByCustomerId } from '../../hooks/gql/query/getByValue/useGetCuMessageByCustomerId';

const GET_CU_MESSAGE_BY_CUS_ID = gql`
  query GetCuMessageByCustomerId($input: MongoIdInput!) {
    getCuMessageByCustomerId(input: $input) {
      _id
      customer_ref {
        _id
        name
      }
      conversations {
        _id
        sender
        content
        sentAt
      }
    }
  }
`;

const AdminSupportPage: React.FC<{}> = (props) => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cuMessage, setCuMessage] = useState('');
  const cuRef = useRef<HTMLDivElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { addCuMessage } = useAddCuMessage();
  const { updateCuMessage } = useUpdateCuMessage();
  const {
    data: cu_messages,
    error: cu_messagesError,
    loading: cu_messagesLoading,
    refetch: cu_messagesRefectch,
  } = useGetCuMessageByCustomerId(GET_CU_MESSAGE_BY_CUS_ID, {
    _id: user._id || '64f8267c7ed1e6aa0166bde1',
  });

  const conversations = cu_messages?.conversations || [];

  const formattedMessages = formatMessages(conversations);

  useEffect(() => {
    const itv = setInterval(() => {
      cu_messagesRefectch();
    }, 5000);

    return () => clearInterval(itv);
  }, []);

  useEffect(() => {
    cuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [conversations.length]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className='admin_support'>
        {!isAuthenticated && (
          <div
            style={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
              padding: 20,
            }}>
            <p style={{ textAlign: 'center' }}>
              Sorry! Support Message is not allowed at this point. Please enter
              your information in home page to get support. Or use the email /
              contact number given in the footer.
            </p>
          </div>
        )}

        {isAuthenticated && (
          <>
            <div className='admin_support-header'>
              <div
                className='admin_support-header_logo'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  minWidth: 50,
                  marginRight: 15,
                }}>
                <Avatar
                  src='/images/logo_white.png'
                  sx={{
                    width: 30,
                    height: 30,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}
                />
                <p>Admin</p>
              </div>

              <div style={{}} className='admin_support-header_cu'>
                <div
                  className='cu_phone'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <PhoneOutlinedIcon
                    style={{ color: '#fff', marginRight: 5 }}
                  />
                  <p>0203 805 7133</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <EmailOutlinedIcon
                    style={{ color: '#fff', marginRight: 5 }}
                  />
                  <p>operations@fully-booked.uk</p>
                </div>
              </div>
            </div>
            <div className='admin_support-body'>
              <div className='admin_support-body--h'>
                <p>
                  {cu_messages === null
                    ? 'Type a new message to start a conversation.'
                    : `You are talking to Admin`}
                </p>
              </div>
              <div className='admin_support-body--c'>
                {/* ITIRATE HERE */}
                {/* DATE */}

                {cu_messages && (
                  <>
                    {formattedMessages.map((m) => {
                      if (typeof m === 'string') {
                        return (
                          <div key={m} className='admin_support-body--c_date'>
                            <p>{m}</p>
                          </div>
                        );
                      } else if (m.sender === 'customer') {
                        return (
                          <motion.div
                            key={m._id}
                            // initial={{ y: 10, opacity: 0 }}
                            // animate={{ y: 0, opacity: 1 }}
                            // transition={{ duration: 0.5 }}
                            className='admin_support-body--c_right'>
                            <div className='conv_message'>
                              <pre className='message_content'>{m.content}</pre>
                            </div>
                            <div className='conv_time'>
                              <p>{moment(m.sentAt).format('HH:mm')}</p>
                            </div>
                          </motion.div>
                        );
                      } else {
                        return (
                          <motion.div
                            key={m._id}
                            // initial={{ y: 10, opacity: 0 }}
                            // animate={{ y: 0, opacity: 1 }}
                            // transition={{ duration: 0.5 }}
                            className='admin_support-body--c_left'>
                            <div className='conv_message'>
                              <Avatar
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 1,
                                  bgcolor: Colors.pclXLight,
                                  color: Colors.primaryColorLight,
                                  boxShadow:
                                    '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                }}
                              />
                              <pre className='message_content'>{m.content}</pre>
                            </div>
                            <div className='conv_time'>
                              <p>{moment(m.sentAt).format('HH:mm')}</p>
                            </div>
                          </motion.div>
                        );
                      }
                    })}
                  </>
                )}
                <div ref={cuRef} />
              </div>
              <div className='admin_support-body--i'>
                <div className='admin_support-body--i_inp'>
                  <textarea
                    name=''
                    id=''
                    placeholder='Type new message...'
                    value={cuMessage}
                    onChange={(e) => {
                      setCuMessage(e.target.value);
                    }}
                    cols={20}
                    rows={2}></textarea>
                </div>
                <div className='admin_support-body--i_sub'>
                  <IconButton
                    onClick={async () => {
                      setIsSubmitting(true);
                      try {
                        if (cu_messages === null && user._id) {
                          await addCuMessage({
                            customer_ref: user._id,
                            conversations: {
                              sender: 'customer',
                              content: cuMessage,
                            },
                          });
                        }

                        if (cu_messages) {
                          await updateCuMessage({
                            _id: cu_messages._id,
                            conversations: {
                              sender: 'customer',
                              content: cuMessage,
                            },
                          });
                        }

                        setCuMessage('');
                        setIsSubmitting(false);

                        setTimeout(() => {
                          cu_messagesRefectch();
                        }, 1000);
                      } catch (err) {
                        setIsSubmitting(false);
                        console.log(err);
                      }
                    }}
                    disabled={!(cuMessage.trim().length >= 2)}
                    style={{ color: '#fff', backgroundColor: '#002661' }}>
                    {isSubmitting ? (
                      <CircularProgress style={{ color: '#fff' }} size={24} />
                    ) : (
                      <SendIcon
                        style={{
                          marginLeft: 3,
                          transform: 'rotate(-45deg)',
                          marginBottom: 3,
                        }}
                      />
                    )}
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminSupportPage;
