import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import Colors from '../../constants/Colors';
import './CustomerInfoDialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  authenticate,
  resetChecked,
  updatedChecked,
} from '../../store/reducer/auth';
import { useIsCustomerEmailExists } from '../../hooks/gql/mutation/action/useIsCustomerEmailExists';
import { useAddCustomer } from '../../hooks/gql/mutation/create/useAddCustomer';

const CustomerInfoDialog: React.FC<{
  isOpen: boolean;
  isReviewPage?: boolean;
}> = ({ isOpen, isReviewPage = false }) => {
  const { isChecked, checked } = useSelector((state: RootState) => state.auth);
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');
  const [name, setName] = useState('');

  const dispatch = useDispatch();
  const { isCustomerEmailExists } = useIsCustomerEmailExists();
  const { addCustomer } = useAddCustomer();

  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    //dispatch(resetChecked());
  }, []);

  return (
    <div className='info_dialog_wrapper'>
      <Dialog
        open={isOpen}
        scroll='body'
        onClose={(reason) => {
          alert(
            "Can't close Dialog Box. To open URL with Referral Link you must have to enter your Contact Information First."
          );
        }}
        disableEscapeKeyDown>
        <DialogTitle>Enter Your Contact Information</DialogTitle>
        <DialogContent ref={descriptionElementRef}>
          <DialogContentText style={{ marginBottom: 20, fontWeight: 'bold' }}>
            {isReviewPage
              ? 'What was your email you used for the trip?'
              : 'Where should we send your quote?'}
          </DialogContentText>
          <DialogContentText style={{ marginBottom: 20 }}>
            {isDisplay &&
              isFound &&
              `Are we correct? We matched your information from our database, If everything seems fine just click confirm button. Or you can change your information by clicking the change info button and by entering different or new email.`}
            {isDisplay &&
              !isFound &&
              'Please double check your email. We did not find any information in our database with this email. Please enter and submit your information so that we can record them for future. It will help you auto fillup information next time.'}
            {!isDisplay &&
              !isReviewPage &&
              "You'll start to receive prices very shortly, but we require your email address to send you the quote. If you've previously used Fully Booked, please enter the email address you used before. This will streamline the booking process for you if you decide to proceed. Thank you."}

            {!isDisplay &&
              isReviewPage &&
              "You must have to provide the email you used for this trip. Or we can't procced with your request."}
          </DialogContentText>
          <TextField
            autoFocus
            style={{ marginBottom: 20 }}
            margin='dense'
            id='email_addr'
            label='Email Address'
            disabled={isDisplay && isFound}
            type='email'
            fullWidth
            variant='outlined'
            value={email || checked.email}
            onChange={(val) => {
              setEmail(val.target.value);
            }}
          />
          {isDisplay && (
            <>
              <TextField
                autoFocus
                margin='dense'
                style={{ marginBottom: 20 }}
                disabled={isDisplay && isFound}
                id='phone_number'
                label='Your phone number'
                type='text'
                value={phone_number || checked.phone_number}
                fullWidth
                variant='outlined'
                onFocus={(e) => {
                  setPhone_number(`${phone_number || checked.phone_number}`);
                }}
                onChange={(val) => {
                  setPhone_number(val.target.value);
                }}
              />

              <TextField
                margin='dense'
                id='name'
                disabled={isDisplay && isFound}
                label='Your name'
                type='text'
                value={name || checked.name}
                fullWidth
                variant='outlined'
                onChange={(val) => {
                  setName(val.target.value);
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions style={{ paddingRight: 25, paddingBottom: 20 }}>
          {!isDisplay && (
            <Button
              disabled={!email.includes('@')}
              onClick={async () => {
                setIsSubmitting(true);
                try {
                  const res = await isCustomerEmailExists({
                    email: email.toLowerCase(),
                  });
                  const customer = res.isCustomerEmailExists;

                  if (customer) {
                    setIsFound(true);
                    dispatch(
                      updatedChecked({
                        isChecked: true,
                        checked: customer,
                      })
                    );
                    setIsSubmitting(false);
                  }
                  setIsDisplay(true);
                } catch (err) {
                  console.log(err);
                  setIsFound(false);
                }

                setIsSubmitting(false);
              }}
              style={{
                backgroundColor: Colors.primaryColorLight,
                color: Colors.light,
                opacity: !email.includes('@') ? 0.5 : 1,
              }}>
              {isSubmitting ? 'Please wait...' : 'Check'}
            </Button>
          )}

          {isDisplay && isFound && (
            <>
              <Button
                onClick={() => {
                  dispatch(resetChecked());
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }}
                style={{
                  backgroundColor: Colors.accentColorDark,
                  color: Colors.light,
                }}>
                Change Info
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    authenticate({ isAuthenticated: true, user: checked })
                  );
                }}
                style={{
                  backgroundColor: Colors.primaryColorLight,
                  color: Colors.light,
                }}>
                Confirm
              </Button>
            </>
          )}

          {isDisplay && !isFound && (
            <Button
              disabled={
                !(
                  email.length > 4 &&
                  phone_number.length > 6 &&
                  name.length > 5
                )
              }
              onClick={async () => {
                setIsSubmitting(true);
                try {
                  const res = await addCustomer({
                    email: email.toLowerCase(),
                    name,
                    phone_number,
                  });

                  const customer = res.addCustomer;

                  if (customer) {
                    dispatch(
                      authenticate({
                        isAuthenticated: true,
                        user: customer,
                      })
                    );
                    setIsSubmitting(false);
                  } else {
                    throw new Error(JSON.stringify(res.error));
                  }
                } catch (err) {
                  console.log(err);
                }
                setIsSubmitting(false);
              }}
              style={{
                backgroundColor: Colors.primaryColorLight,
                color: Colors.light,
                opacity: !(
                  email.length > 4 &&
                  phone_number.length > 6 &&
                  name.length > 5
                )
                  ? 0.5
                  : 1,
              }}>
              {isSubmitting ? 'Please wait...' : 'Submit'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerInfoDialog;
