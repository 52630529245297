import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  jwt: string;
  isAuthenticated: boolean;
  user: {
    _id: string;
    name: string;
    email: string;
    phone_number: string;
  };
  isChecked: boolean;
  checked: {
    _id: string;
    name: string;
    email: string;
    phone_number: string;
  };
}

const initialState: AuthState = {
  jwt: '',
  isAuthenticated: false,
  user: {
    _id: '',
    email: '',
    name: '',
    phone_number: '',
  },
  isChecked: false,
  checked: {
    _id: '',
    email: '',
    name: '',
    phone_number: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (
      state,
      data: PayloadAction<{
        isAuthenticated: boolean;
        user: {
          _id: string;
          name: string;
          email: string;
          phone_number: string;
        };
      }>
    ) => {
      state.isAuthenticated = data.payload.isAuthenticated;
      state.user = data.payload.user;
    },

    updatedChecked: (
      state,
      data: PayloadAction<{
        isChecked: boolean;
        checked: {
          _id: string;
          name: string;
          email: string;
          phone_number: string;
        };
      }>
    ) => {
      state.checked = data.payload.checked;
      state.isChecked = data.payload.isChecked;
    },

    resetChecked: (state) => {
      state.checked = initialState.checked;
      state.isChecked = false;
    },

    resetAuth: (state) => {
      state.isAuthenticated = false;
      state.user = initialState.user;
    },
  },
});

// Action creators are generated for each case reducer function
export const { authenticate, resetAuth, updatedChecked, resetChecked } =
  authSlice.actions;

export default authSlice.reducer;
