import Colors from '../../constants/Colors';
import { gBaseUrl } from '../../utils/config';
import { getNameAvatar } from '../../utils/functions';
import './OfferCardListItem.scss';
import { Avatar } from '@mui/material';

const OfferCardListItem: React.FC<{
  id: string;
  contact_name: string;
  photo: string;
  isPhotoVisible: boolean;
  referral_code: string;
  i: number;
  activeProposalId: string;
  isRecommended: boolean;
  onItemClick: (id: string) => void;
}> = ({
  id,
  contact_name,
  photo,
  isPhotoVisible,
  referral_code,
  i,
  onItemClick,
  isRecommended,
  activeProposalId,
}) => {
  return (
    <div
      className='offers_cards_item'
      onClick={() => {
        onItemClick(id);
      }}>
      <div className='offers_cards_item_pos'>
        <p>{i}</p>
      </div>
      <div
        className='offers_cards_item_cont'
        style={{
          border:
            activeProposalId === id
              ? `1px solid ${Colors.primaryColorLight}`
              : `1px solid transparent`,
          position: 'relative',
          paddingTop: isRecommended ? 20 : 10,
        }}>
        {isRecommended && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              background: Colors.primaryColorLight,
              borderTopRightRadius: 5,
            }}>
            <p style={{ fontSize: 12, padding: 3, color: Colors.light }}>
              Recommended
            </p>
          </div>
        )}

        {isPhotoVisible ? (
          <Avatar
            src={`${gBaseUrl}/photos/avatars/${photo}`}
            sx={{
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : (
          <Avatar
            sx={{
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}>
            {getNameAvatar(contact_name)}
          </Avatar>
        )}
        <div className='business_info'>
          <p className='business_name'>{contact_name}</p>
          <p className='business_id'>#{referral_code}</p>
        </div>
      </div>
    </div>
  );
};

export default OfferCardListItem;
