import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './MainNavigation.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const MainNavigation = () => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className='navbar'>
      <div className='container'>
        <div className='logo'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              minWidth: 50,
            }}>
            <img
              onClick={() => {
                navigate('/');
              }}
              className='logo_desk'
              style={{
                width: 85,
                cursor: 'pointer',
              }}
              src='/images/main_logo.png'
              alt='Logo'
            />

            <img
              className='logo_mob'
              style={{
                width: 35,
                borderRadius: 100,
                cursor: 'pointer',
                background: '#fff',
                boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
              }}
              src='/images/main_logo.png'
              alt='Logo'
            />
          </div>
        </div>
        <div className='menu-icon' onClick={handleShowNavbar}>
          {!showNavbar ? <MenuIcon /> : <CloseIcon />}
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to='/'>Home</NavLink>
            </li>
            <li>
              <NavLink to='/enquiries'>My Enquires</NavLink>
            </li>

            <li>
              <NavLink to='/reviews'>Reviews</NavLink>
            </li>

            <li>
              <NavLink to='/admin-support'>Admin Support</NavLink>
            </li>
            <li>
              <NavLink to='/about-us'>About Us</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
