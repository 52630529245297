import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  AddCustomerInput,
  Customer,
} from '../../../../types/schema/customer.types';

const GQL_MUT = gql`
  mutation AddCustomer($input: AddCustomerInput!) {
    addCustomer(input: $input) {
      _id
      name
      email
      phone_number
    }
  }
`;

// Define the types for the mutation response and input
interface AddCustomerMutationData {
  addCustomer: Customer;
}

interface AddCustomerInputType {
  input: AddCustomerInput;
}

// Define custom hook
export const useAddCustomer = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [addCustomerGql, { data, error, loading }] = useMutation<
    AddCustomerMutationData,
    AddCustomerInputType
  >(GQL_MUT);

  const addCustomer = async (input: AddCustomerInput) => {
    const { data, errors } = await addCustomerGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      addCustomer: data?.addCustomer,
      error: errors ? errors[0].message : null,
    };
  };

  return { addCustomer, data, error, loading };
};
