/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './EnquiryDetailsPaymentPage.scss';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import axios from 'axios';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { URLConfig } from '../../utils/config';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import moment from 'moment';
import { useGetReferralDetailsById } from '../../hooks/gql/query/getById/useGetReferralDetailsById';
import { useGetSetting } from '../../hooks/gql/query/useGetSetting';
import { useGetProposalDetailsById } from '../../hooks/gql/query/getById/useGetProposalDetailsById';

const GET_SETTINGS = gql`
  query GetSetting {
    getSetting {
      _id
      name
      upfrontPercentage
      maxNegBalance
    }
  }
`;

const GET_ENQ_DETAILS = gql`
  query GetReferralDetailsById($input: MongoIdInput!) {
    getReferralDetailsById(input: $input) {
      _id
      status
      trip_info {
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        tripType
        pickup_datetime
        return_datetime
        passenger_count
        travelling_reason
      }
    }
  }
`;

const GET_PROPOSAL_DETAILS_BY_ID = gql`
  query GetProposalDetailsById($input: MongoIdInput!) {
    getProposalDetailsById(input: $input) {
      _id
      proposal_amount
      agreed_amount
      selected_vehicle
      proposed_referral_ref {
        _id
      }
      proposed_by {
        _id
      }
    }
  }
`;

const EnquiryDetailsPaymentPage: React.FC<{}> = (props) => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();
  const { slug } = useParams();
  const { search } = useLocation();

  const [isPaying, setIsPaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentProposal, setCurrentProposal] = useState(
    '64f8267c7ed1f6aa0166bde1'
  );

  const {
    data: settings,
    error: settingsError,
    loading: settingsLoading,
    refetch: settingsRefectch,
  } = useGetSetting(GET_SETTINGS);

  const {
    data: referral,
    error: referralError,
    loading: referralLoading,
    refetch: referralRefectch,
  } = useGetReferralDetailsById(GET_ENQ_DETAILS, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: proposal,
    error: proposalError,
    loading: proposalLoading,
    refetch: proposalRefectch,
  } = useGetProposalDetailsById(GET_PROPOSAL_DETAILS_BY_ID, {
    _id: currentProposal,
  });

  const percentage = settings?.upfrontPercentage || 10;
  const trip_info = referral?.trip_info;

  useEffect(() => {
    const prID = search.split('=')[1];

    if (prID) {
      setCurrentProposal(prID);
      proposalRefectch();
    } else {
      navigate(`/enquiries/${slug}`);
    }
  }, [search.split('=')[1]]);

  return (
    <div className='payment_page_content_wrapper'>
      <div className='payment_page_section'>
        <div className='payment_page_section_ad'>
          {referralLoading && <h1>Please wait...</h1>}
          {trip_info && proposal && (
            <>
              <h1>Confirm Your Booking</h1>
              <p className='payment_page_section_ad-location'>
                From <span>{trip_info.pickup_location.location_name}</span> to{' '}
                <span>{trip_info.destination.location_name}</span>
              </p>
              <p className='payment_page_section_ad-amount'>
                {getAmountWithCurrency(
                  proposal.agreed_amount * (percentage / 100)
                )}
              </p>
              <p className='payment_page_section_ad-amount_note'>
                Please tell the operator to update the price if you negotiate
                the amount.
              </p>
              <p className='payment_page_section_ad-type'>
                {formatString(trip_info?.tripType)} ({trip_info.passenger_count}{' '}
                Passengers)
              </p>
              <p className='payment_page_section_ad-date'>
                {moment(trip_info.pickup_datetime).format('lll')}
              </p>
              <br />
              <img
                className='bus_img'
                style={{
                  cursor: 'pointer',
                  borderRadius: 10,
                }}
                src='https://unblast.com/wp-content/uploads/2022/02/Online-Car-Booking-Illustration.jpg'
                alt='Bus'
              />
            </>
          )}
        </div>
        <div className='payment_page_section_form'>
          {errorMessage && (
            <div style={{ marginBottom: 10 }}>
              <p style={{ color: 'red' }}>{errorMessage}</p>
            </div>
          )}

          {proposal && (
            <PaymentForm
              applicationId={URLConfig.squareAppId!}
              cardTokenizeResponseReceived={async (token) => {
                setErrorMessage('');
                setIsPaying(true);
                const amount = proposal.agreed_amount * (percentage / 100);

                try {
                  await axios({
                    method: 'POST',
                    url: URLConfig.squareUpfrontPaymentSessionUrl,
                    headers: {
                      'Content-type': 'application/json',
                    },
                    data: {
                      sourceId: token.token,
                      proposalId: currentProposal,
                      customerEmail: user.email,
                      customerId: user._id,
                      amount,
                    },
                  });

                  setErrorMessage('');
                  setIsPaying(false);
                  navigate(`/enquiries/${slug}?status=success`);
                } catch (err: any) {
                  setIsPaying(false);
                  setErrorMessage(
                    formatString(
                      err?.response?.data?.error?.errors[0]?.detail
                    ) || 'Payment failed! Please contact support.'
                  );
                }
              }}
              locationId={URLConfig.squareLocationId!}>
              <CreditCard
                render={(Button) => (
                  <Button
                    isLoading={isPaying}
                    style={{
                      fontFamily: 'DM Sans',
                      backgroundColor: isPaying ? '#ddd' : '#47C56C',
                      fontSize: '17px',
                      color: '#fff',
                    }}>
                    {isPaying
                      ? 'Please wait...'
                      : `Pay 
                  ${getAmountWithCurrency(
                    proposal.agreed_amount * (percentage / 100)
                  )}`}
                  </Button>
                )}
              />
            </PaymentForm>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnquiryDetailsPaymentPage;
