import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import {
  Review,
  ReviewStatsObjType,
} from '../../../../types/schema/review.types';
import { MongoIdInput } from '../../../../types/schema/utils.types';

interface GetAllReviewsByCompanyIdStatsHook {
  data: ReviewStatsObjType | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllReviewsByCompanyIdByPageStats: ReviewStatsObjType;
    }>
  >;
}

interface GetAllReviewsByCompanyIdStatsMutationData {
  getAllReviewsByCompanyIdByPageStats: ReviewStatsObjType;
}

interface GetAllReviewsByCompanyIdStatsInputType {
  input: MongoIdInput;
}

export function useGetAllReviewsByCompanyIdStats(
  query: DocumentNode,
  input: MongoIdInput
): GetAllReviewsByCompanyIdStatsHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllReviewsByCompanyIdStatsMutationData,
    GetAllReviewsByCompanyIdStatsInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllReviewsByCompanyIdByPageStats,
    loading,
    error,
    refetch,
  };
}
