import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Referral,
  ReferralTokenInput,
} from '../../../../types/schema/referral.types';

const GQL_MUT = gql`
  mutation IsReferralTokenExists($input: ReferralTokenInput!) {
    isReferralTokenExists(input: $input) {
      _id
      status
      referral_token
    }
  }
`;

// Define the types for the mutation response and input
interface IsReferralTokenExistsMutationData {
  isReferralTokenExists: Referral;
}

interface IsReferralTokenExistsInputType {
  input: ReferralTokenInput;
}

export const useIsReferralTokenExists = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [isReferralTokenExistsGql, { data, error, loading }] = useMutation<
    IsReferralTokenExistsMutationData,
    IsReferralTokenExistsInputType
  >(GQL_MUT);

  const isReferralTokenExists = async (input: ReferralTokenInput) => {
    const { data, errors } = await isReferralTokenExistsGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      isReferralTokenExists: data?.isReferralTokenExists,
      error: errors ? errors[0].message : null,
    };
  };

  return { isReferralTokenExists, data, error, loading };
};
