export const labelsRating: { [index: string]: string } = {
  [0.5]: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

export const getCusometResponses = (key: number) => {
  const responses: Record<number, string[]> = {
    0: ['Yes', 'No'],
    1: ['Thanks'],
  };

  return responses[key];
};

export const vahicle_types_arr = [
  {
    label: '7 Seater Car',
    value: '7_seater_car',
  },
  { label: '9 Seater MVP', value: '9_seater_mvp' },
  { label: '12 Seater Minibus', value: '12_seater_minibus' },
  { label: '15 Seater Minibus', value: '15_seater_minibus' },
  { label: '17 Seater Minibus', value: '17_seater_minibus' },
  { label: '19 Seater Mini Coach', value: '19_seater_mini_coach' },
  { label: '22 Seater Mini Coach', value: '22_seater_mini_coach' },
  { label: '25 Seater Coach', value: '25_seater_coach' },
  { label: '29 Seater Coach', value: '29_seater_coach' },
  { label: '33 Seater Coach', value: '33_seater_coach' },
  { label: '34 Seater Coach', value: '34_seater_coach' },
  { label: '35 Seater Coach', value: '35_seater_coach' },
  { label: '49 Seater Coach', value: '49_seater_coach' },
  { label: '53 Seater Coach', value: '53_seater_coach' },
  { label: '57 Seater Coach', value: '57_seater_coach' },
  { label: '63 Seater Coach', value: '63_seater_coach' },
  { label: '87 Seater Coach', value: '87_seater_coach' },
];

export const travelling_reason_arr = [
  { label: 'School Trip', value: 'school_trip' },
  { label: 'Night Out', value: 'night_out' },
  { label: 'Wedding', value: 'wedding' },
  { label: 'Europe Travel', value: 'europe_travel' },
  { label: 'Festival', value: 'festival' },
  { label: 'Airport', value: 'airport' },
  { label: 'Sports Event', value: 'sports_event' },
  { label: 'UK/City Tour', value: 'uk/city_trip' },
  { label: 'Outing/Day Trip', value: 'outing/day_trip' },
  { label: 'Business', value: 'business' },
  { label: 'Beach Trip', value: 'beach_trip' },
  { label: 'Others', value: 'others' },
];

export const luggage_type_arr = [
  { label: 'None', value: 'none' },
  { label: 'Small - E.g. Day Bags', value: 'small_e.g._day_bags' },
  {
    label: 'Medium - E.g. 10kg Suitcase/Hand Luggage',
    value: 'medium_e.g._10kg_suitcase',
  },
  { label: 'Large - E.g. 20kg Suitcase', value: 'large_e.g._20kg_suitcase' },
  { label: 'Other', value: 'other' },
];

const ukPhoneNumberFormats = [
  '07854 768 709',
  '+44 7854 768 709',
  '+44 7854 768709',
  '+447854 768709',
  '+447854768709',
  '+4407854768709',
  '07854 768 709',
  '90787654870',
  '+44 7854-768-709',
  '+44(0)7854 768 709',
  '0044 7854-768-709',
  '(07854) 768709',
  '07854-768-709',
  '07854768709',
  '+44 7854-768-709',
  '+44(0)7854 768 709',
  '0044 7854-768-709',
  '(07854) 768709',
  '07854-768-709',
  '07854768709',
  '7854768709',
  '+44(0)7854 768 709',
  '00447854 768 709',
  '(07854) 768 709',
  '07854-768709',
  '07854 768709',
  '07854768709',
  '+447854-768-709',
  '0044 7854 768709',
  '(07854)768709',
  '00447854-768-709',
  '+447854768709',
  '00447854-768709',
  '+4407854768709',
  '07854-768 709',
  '07854 768 709',
  '0044(0)7854 768709',
  '00447854 768 709',
  '(07854)768 709',
  '+447854-768709',
  '0044 7854-768-709',
  '07854-768 709',
  '07854 768 709',
  '0044(0)7854-768709',
  '00447854 768709',
  '(07854)768 709',
  '+447854-768 709',
  '0044 7854-768 709',
  '+44 7854-768709',
  '+447854768 709',
  '00447854-768 709',
  '+44 7854 768 709',
  '+447854 768709',
  '+4407854 768 709',
  '07854-768709',
  '07854 768 709',
  '+44(0)7854 768709',
  '+44 7854-768709',
  '+447854-768709',
  '0044 7854 768709',
  '00447854-768709',
  '(07854) 768709',
  '(07854)768709',
  '0044(0)7854-768709',
  '0044 7854-768709',
  '07854-768709',
  '+447854-768709',
  '0044(0)7854-768 709',
  '00447854-768 709',
  '(07854)768 709',
  '+447854-768 709',
  '0044 7854-768 709',
  '07854-768 709',
  '0044(0)7854-768 709',
  '00447854-768 709',
  '(07854)768 709',
  '+447854-768 709',
  '0044 7854-768 709',
  '07854-768 709',
  // Additional variants
  '07854 768709',
  '+44 7854 768709',
  '+44 7854768709',
  '+447854768709',
  '+4407854 768709',
  '07854-768709',
  '+44(0)7854 768709',
  '+44 7854-768709',
  '+447854-768709',
  '0044 7854 768709',
  '00447854-768709',
  '(07854) 768709',
  '(07854)768709',
  '0044(0)7854-768709',
  '0044 7854-768709',
  '07854-768709',
  '+447854-768709',
  '0044(0)7854-768 709',
  '00447854-768 709',
  '(07854)768 709',
  '+447854-768 709',
  '0044 7854-768 709',
  '07854-768 709',
];

const regexPatterns = ukPhoneNumberFormats.map(
  (example) =>
    new RegExp(
      example
        .replace(/[0-9]/g, '\\d')
        .replace(/[-()+]/g, '\\$&')
        .replace(/\s/g, '\\s?')
    )
);

export const ukPhoneRegex = new RegExp(
  regexPatterns.map((pattern) => pattern.source).join('|')
);

export const emailAndDomainRegex =
  /(?:\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b|\b(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(?:\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?)\b/g;

export const spelledOutNumbersRegex =
  /\b(zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred)\b/g;

export const commonWords = [
  'email',
  'gmail',
  'gml',
  '@',
  'dot',
  '.com',
  'phone',
  'number',
  'yahoo',
  'address',
  'addr',
  'eml',
  '.uk',
  'contact',
];

export const getCommonWordsRegEx = (words: string[] = []) => {
  const mergedWords = [...commonWords, ...words];

  return new RegExp(mergedWords.join('|'), 'i');
};
