import { getNameAvatar } from '../../utils/functions';
import './CompanyReviewSelector.scss';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import CloseIcon from '@mui/icons-material/Close';
import { gBaseUrl } from '../../utils/config';

const CompanyReviewSelector: React.FC<{
  photo: string;
  isPhotoVisible: boolean;
  contact_name: string;
  referral_code: string;
  onCalcel: () => void;
}> = ({ photo, isPhotoVisible, contact_name, referral_code, onCalcel }) => {
  return (
    <div className='company_review_selector'>
      <div className='company_review_selector_info'>
        {isPhotoVisible ? (
          <Avatar
            src={`${gBaseUrl}/photos/avatars/${photo}`}
            sx={{
              width: 40,
              height: 40,
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : (
          <Avatar
            sx={{
              width: 40,
              height: 40,
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}>
            {getNameAvatar(contact_name)}
          </Avatar>
        )}
        <div className='business_info'>
          <p className='business_name'>{contact_name}</p>
          <p className='business_id'>#{referral_code}</p>
        </div>
      </div>
      <div className='company_review_selector_action' onClick={onCalcel}>
        <CloseIcon color='error' />
      </div>
    </div>
  );
};

export default CompanyReviewSelector;
