import React, { useState } from 'react';
import './TestimonialsPage.scss';
import { gql } from '@apollo/client';
import CustomButton from '../../components/UI/CustomButton';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import TestimonialListItem from '../../components/ListItem/TestimonialListItem';
import { useGetAllTestimonialsByPage } from '../../hooks/gql/query/getAll/useGetAllTestimonialsByPage';

const GET_TESTIMONIALS_BY_PAGE = gql`
  query GetAllTestimonialsByPage($input: PaginationWithBooleanInput!) {
    getAllTestimonialsByPage(input: $input) {
      _id
      message
      rating
      isVisible
      added_by {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
    }
  }
`;

const TestimonialsPage: React.FC<{}> = (props) => {
  const [page_number, setPage_number] = useState(1);

  const {
    data: testimonials,
    error: testimonialsError,
    loading: testimonialsLoading,
    refetch: testimonialsRefectch,
  } = useGetAllTestimonialsByPage(GET_TESTIMONIALS_BY_PAGE, {
    bool: true,
    page: page_number,
    item_per_page: 20,
  });

  return (
    <>
      <div className='testimonials_cards'>
        {testimonialsLoading && <LoadingSpinner />}
        {testimonials &&
          testimonials.map((tm) => {
            return (
              <TestimonialListItem
                key={tm._id}
                rating={tm.rating}
                message={tm.message}
                added_by={{
                  photo: tm.added_by.photo,
                  isPhotoVisible: tm.added_by.isPhotoVisible,
                  contact_name: tm.added_by.contact_name,
                  referral_code: tm.added_by.referral_code,
                }}
              />
            );
          })}
      </div>

      <div className='testimonials_action_wrapper'>
        <CustomButton
          title='Load More'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            testimonialsRefectch();
          }}
          isLoading={testimonialsLoading}
        />
      </div>
    </>
  );
};

export default TestimonialsPage;
