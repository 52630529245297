import React from 'react';
import './AboutUsPage.scss';

const AboutUsPage: React.FC<{}> = (props) => {
  return (
    <>
      <div className='about_us_img'>
        <div className='about_us_img_left'>
          <p>Welcome to Fully Booked</p>

          <br />

          <p>
            Fully Booked is the UK’s first platform dedicated to connecting
            travelers with the best minibus hire services by allowing vetted
            transport companies to refer you to other reliable and vetted
            operators across the country. Whether you are planning a corporate
            event, a family outing, a school trip, or any other group adventure,
            we've got you covered.
          </p>

          <p>
            Our vision is to revolutionize the way people organize group travel
            by allowing customers to fill out 1 inquiry and receive multiple
            quotations from various companies, without the hassle of endless
            phone calls and inquiries. We aim to be the go-to platform for
            individuals and organizations seeking reliable minibus hire
            solutions.
          </p>
        </div>

        <div className='about_us_img_right'>
          <img
            style={{
              height: 220,
            }}
            src='/images/bus.png'
            alt='Bus'
          />
        </div>
      </div>

      <br />

      <h3>How it Works:</h3>
      <p>
        At Fully Booked, we have created a simple and efficient process for
        connecting travelers to minibus hire services. Here's how it works:
      </p>

      <ul>
        <li>
          Submit Your Requirements: Tell us about your group travel plans by
          filling out a quick form.
        </li>
        <li>
          Get Matched with Minibus Hire Companies: Once we receive your request,
          our advanced matching algorithm will instantly connect you with
          minibus hire companies that best meet your criteria. These companies
          are carefully vetted to ensure they maintain high standards of
          service, safety, and reliability.
        </li>
        <li>
          Compare and Choose: You will receive quotes and offers from the
          matched minibus hire companies. Compare the prices, read customer
          testimonials, and evaluate the services offered by each company to
          make an informed decision.
        </li>
        <li>
          Book with Confidence: After selecting the ideal minibus hire service
          that fits your needs and budget, you can make the booking directly
          with your company of choice.
        </li>
      </ul>

      <br />

      <h3>Why Choose Fully Booked:</h3>

      <ul>
        <li>
          Extensive Network: We have an extensive network of reputable minibus
          hire companies across the country, providing you with numerous options
          to choose from.
        </li>
        <li>
          We are the only company that provides you with companies in ranked
          order with previous customer testimonials as well.
        </li>
        <li>
          User-Friendly Platform: Our user-friendly interface ensures a smooth
          and hassle-free booking experience. You can access our platform from
          any device, making it convenient for you to plan your group travel on
          the go.
        </li>
        <li>
          Transparent and Trustworthy: We believe in transparency and honesty.
          All the minibus hire companies on our platform are verified and
          testimonialed, giving you the confidence to book with trust.
        </li>
        <li>
          Excellent Customer Support: Our dedicated customer support team is
          always ready to assist you with any queries or concerns you may have.
          We strive to provide exceptional service and ensure your group travel
          plans go off without a hitch.
        </li>
      </ul>

      <br />

      <p>
        Join the Fully booked community today and experience the convenience of
        planning your group travel with ease. Let us take the stress out of
        organizing transportation for your next adventure!
      </p>

      <br />

      <p>
        If you have any questions or need assistance, feel free to reach out to
        our friendly team at operations@fully-booked.uk.
      </p>

      <br />
      <p>Happy travels,</p>
      <p>The Fully Booked Team</p>
    </>
  );
};

export default AboutUsPage;
