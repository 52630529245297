export default {
  // Light Colors
  primaryColorLight: '#47C56C',
  pclXLight: '#BFE7D0',
  secondaryColorLight: '#4792C5',
  accentColorLight: '#468A32',
  light: '#ffffff',
  lightBg: '#F3F8FF',
  footerBg: '#002661',

  // Dark Colors
  primaryColorDark: '#C3A146',
  pcdXLight: '#F9F188',
  secondaryColorDark: '#9d974a',
  accentColorDark: '#e16a94',
  dark: '#000000',

  //Common Colors
  grey: '#F3F3F3',
  lightGrey: '#C9C9C9',
  darkGrey: '#C1C7D0',
  darkerGrey: '#747474',
  fontColor1: '#93A1AC',
  fontColor2: '#324A59',
};
