import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import CustomButton from '../../components/UI/CustomButton';
import './ReviewsPage.scss';
import Rating from '@mui/material/Rating';
import CompanyReviewSelector from '../../components/Utils/CompanyReviewSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUserDetailsById } from '../../hooks/gql/query/getById/useGetUserDetailsById';
import { useGetAllReviewsByPage } from '../../hooks/gql/query/getAll/useGetAllReviewsByPage';
import { useGetAllReviewsStats } from '../../hooks/gql/query/getAll/useGetAllReviewsStats';
import { useGetAllReviewsByCompanyId } from '../../hooks/gql/query/getAll/useGetAllReviewsByCompanyId';
import { useGetAllReviewsByCompanyIdStats } from '../../hooks/gql/query/getAll/useGetAllReviewsByCompanyIdStats';

const GET_REVIEWS_BY_PAGE = gql`
  query GetAllReviewsByPage($input: PaginationWithBooleanInput!) {
    getAllReviewsByPage(input: $input) {
      _id
      review_by {
        _id
        name
      }
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
      message
      rating
      createdAt
    }
  }
`;

const GET_REVIEWS_BY_PAGE_STATS = gql`
  query GetAllReviewsStats {
    getAllReviewsStats {
      _id
      numRating
      averageRating
    }
  }
`;

const GET_REVIEWS_BY_PAGE_BY_COMPID = gql`
  query GetAllReviewsByCompanyIdByPage($input: PaginationWithIdInput!) {
    getAllReviewsByCompanyIdByPage(input: $input) {
      _id
      review_by {
        _id
        name
      }
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
      message
      rating
      createdAt
    }
  }
`;

const GET_REVIEWS_BY_PAGE_BY_COMPID_STATS = gql`
  query GetAllReviewsByCompanyIdByPageStats($input: MongoIdInput!) {
    getAllReviewsByCompanyIdByPageStats(input: $input) {
      _id
      numRating
      averageRating
    }
  }
`;

const GET_USER_QUE = gql`
  query GetUserDetailsById($input: MongoIdInput!) {
    getUserDetailsById(input: $input) {
      _id
      contact_name
      referral_code
      photo
      isPhotoVisible
    }
  }
`;

const ReviewsPage = () => {
  const [page_number, setPage_number] = useState(1);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState(
    '65a88d3c1464a102af9d1704'
  );

  const {
    data: user_info,
    error: user_infoError,
    loading: user_infoLoading,
    refetch: user_infoRefectch,
  } = useGetUserDetailsById(GET_USER_QUE, { _id: selectedCompany });

  const {
    data: all_reviews,
    error: all_reviewsError,
    loading: all_reviewsLoading,
    refetch: all_reviewsRefectch,
  } = useGetAllReviewsByPage(GET_REVIEWS_BY_PAGE, {
    page: page_number,
    item_per_page: 20,
    bool: true,
  });

  const {
    data: all_reviews_stats,
    error: all_reviews_statsError,
    loading: all_reviews_statsLoading,
    refetch: all_reviews_statsRefectch,
  } = useGetAllReviewsStats(GET_REVIEWS_BY_PAGE_STATS);

  const {
    data: all_reviews_bid,
    error: all_reviews_bidError,
    loading: all_reviews_bidLoading,
    refetch: all_reviews_bidRefectch,
  } = useGetAllReviewsByCompanyId(GET_REVIEWS_BY_PAGE_BY_COMPID, {
    _id: selectedCompany,
    page: page_number,
    item_per_page: 20,
  });

  const {
    data: all_reviews_bid_stats,
    error: all_reviews_bid_statsError,
    loading: all_reviews_bid_statsLoading,
    refetch: all_reviews_bid_statsRefectch,
  } = useGetAllReviewsByCompanyIdStats(GET_REVIEWS_BY_PAGE_BY_COMPID_STATS, {
    _id: selectedCompany,
  });

  const cID = search.split('=')[1];

  let reviews = all_reviews;
  let reviewStats = all_reviews_stats;
  const company = user_info;

  useEffect(() => {
    if (cID) {
      setSelectedCompany(cID);
      user_infoRefectch();
      all_reviewsRefectch();
      all_reviews_statsRefectch();
      all_reviews_bidRefectch();
      all_reviews_bid_statsRefectch();
    }
  }, [cID]);

  if (cID) {
    reviews = all_reviews_bid;
    reviewStats = all_reviews_bid_stats;
  }

  return (
    <>
      <div className='reviews_header'>
        <div className='reviews_header-filter'>
          {cID && company && (
            <>
              <p>All Reviews For</p>
              <CompanyReviewSelector
                contact_name={company?.contact_name}
                photo={company?.photo}
                isPhotoVisible={company?.isPhotoVisible}
                referral_code={company?.referral_code}
                onCalcel={() => {
                  navigate('/reviews');
                }}
              />
            </>
          )}
        </div>

        {reviewStats && (
          <div className='reviews_header-average'>
            <p style={{ marginRight: 10 }}>
              Average Customer Rating {reviewStats?.averageRating.toFixed(2)}
            </p>
            <Rating
              name='simple-controlled'
              value={reviewStats?.averageRating}
              precision={0.1}
              disabled={true}
              size='small'
              onChange={(event, newValue) => {
                //
              }}
            />

            <div className='rating_status'>
              <p>({reviewStats?.numRating})</p>
            </div>
          </div>
        )}
      </div>
      <div className='reviews_cards'>
        {all_reviewsLoading && !reviews && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <LoadingSpinner />
          </div>
        )}

        {reviews?.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '100px',
            }}>
            <p>No Reviews Found</p>
          </div>
        )}

        {reviews?.map((rv: any) => {
          return (
            <ReviewListItem
              _id={rv._id}
              key={rv._id}
              createdAt={rv.createdAt}
              rating={rv.rating}
              message={rv.message}
              contact_name={rv.review_to.contact_name}
              referral_code={rv.review_to.referral_code}
              photo={rv.review_to.photo}
              isPhotoVisible={rv.review_to.isPhotoVisible}
              review_by={{
                name: rv.review_by.name,
              }}
            />
          );
        })}
      </div>

      <div className='reviews_action_wrapper'>
        <CustomButton
          title='Load More'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            all_reviewsRefectch();
            all_reviews_bidRefectch();
          }}
          isLoading={all_reviewsLoading}
        />
      </div>

      <div className='footer_content'></div>
    </>
  );
};

export default ReviewsPage;
