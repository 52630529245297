import { Suspense } from 'react';
import Layout from './components/Layout/Layout';
import LoadingSpinner from './components/UI/LoadingSpinner';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from './pages/error/ErrorPage';
import HomePage from './pages/home/HomePage';
import PrivacyPageApp from './pages/privacy/PrivacyPageApp';
import TermsPageApp from './pages/terms/TermsPageApp';
import HomePageSuccess from './pages/home/HomePageSuccess';
import EnquiriesPage from './pages/enquires/EnquiriesPage';
import EnquirySuccessPage from './pages/enquires/EnquirySuccessPage';
import EnquiryDetailsPage from './pages/enquires/EnquiryDetailsPage';
import TestimonialsPage from './pages/testimonials/TestimonialsPage';
import AboutUsPage from './pages/about/AboutUsPage';
import PrivacyPage from './pages/privacy/PrivacyPage';
import TermsPage from './pages/terms/TermsPage';
import AboutUsPageApp from './pages/about/AboutUsPageApp';
import AdminSupportPage from './pages/support/AdminSupportPage';
import ReviewsPage from './pages/reviews/ReviewsPage';
import EnquiryReviewsWritingPage from './pages/enquires/EnquiryReviewsWritingPage';
import EnquiryDetailsPaymentPage from './pages/enquires/EnquiryDetailsPaymentPage';
import PaymentPageApp from './pages/payment/PaymentPageApp';

const App = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className='centered'>
            <LoadingSpinner />
          </div>
        }>
        <Routes>
          <Route path='/'>
            <Route index={true} element={<HomePage />} />
            <Route path='enquiry-sent' element={<HomePageSuccess />} />
          </Route>

          <Route path='enquiries'>
            <Route index={true} element={<EnquiriesPage />} />
            <Route path='success' element={<EnquirySuccessPage />} />
            <Route path='review' element={<EnquiryReviewsWritingPage />} />
            <Route path=':slug' element={<EnquiryDetailsPage />} />
            <Route
              path=':slug/payment'
              element={<EnquiryDetailsPaymentPage />}
            />
          </Route>

          <Route path='testimonials'>
            <Route index={true} element={<TestimonialsPage />} />
          </Route>

          <Route path='reviews'>
            <Route index={true} element={<ReviewsPage />} />
          </Route>

          <Route path='admin-support'>
            <Route index={true} element={<AdminSupportPage />} />
          </Route>

          <Route path='about-us'>
            <Route index={true} element={<AboutUsPage />} />
          </Route>

          <Route path='privacy-policy'>
            <Route index={true} element={<PrivacyPage />} />
          </Route>

          <Route path='terms-conditions'>
            <Route index={true} element={<TermsPage />} />
          </Route>

          <Route path='/privacy-app' element={<PrivacyPageApp />} />
          <Route path='/terms-app' element={<TermsPageApp />} />
          <Route path='/about-app' element={<AboutUsPageApp />} />
          <Route path='/payment-app' element={<PaymentPageApp />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
