import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './EnquiryReviewsWritingPage.scss';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { labelsRating } from '../../utils/data';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import {
  formatString,
  getLabelText,
  getNameAvatar,
} from '../../utils/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertModal from '../../components/Modal/AlertModal';
import { gql } from '@apollo/client';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import CustomerInfoDialog from '../../components/Dialog/CustomerInfoDialog';
import { gBaseUrl } from '../../utils/config';
import { useGetReviewDetailsById } from '../../hooks/gql/query/getById/useGetReviewDetailsById';
import { useUpdateReviewByCustomer } from '../../hooks/gql/mutation/update/useUpdateReviewByCustomer';

const GET_REV_DETAILS_BY_ID = gql`
  query GetReviewDetailsById($input: MongoIdInput!) {
    getReviewDetailsById(input: $input) {
      _id
      isVisible
      message
      rating
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
      booking_ref {
        _id
        trip_info {
          travelling_reason
          pickup_datetime
          pickup_location {
            location_name
          }
          destination {
            location_name
          }
        }
      }
    }
  }
`;

const EnquiryReviewsWritingPage: React.FC<{}> = (props) => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );
  const { search } = useLocation();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = React.useState<number | null>(5);
  const [valueTextArea, setValueTextArea] = React.useState<string>('');
  const [hover, setHover] = React.useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);

  const { updateReviewByCustomer } = useUpdateReviewByCustomer();

  const {
    data: review,
    error: reviewError,
    loading: reviewLoading,
    refetch: reviewRefectch,
  } = useGetReviewDetailsById(GET_REV_DETAILS_BY_ID, {
    _id: search.split('=')[1] || '65a521b2db4765567ac4704c',
  });

  const booking = review?.booking_ref;
  const trip_info = booking?.trip_info;

  useEffect(() => {
    const refID = search.split('=')[1];
    if (!isAuthenticated) {
      setIsAuthModalVisible(true);
    } else {
      setIsAuthModalVisible(false);
    }
    if ((!refID || reviewError) && isAuthenticated) {
      setIsModalVisible(true);
    } else {
      if (isAuthenticated) {
        setIsModalVisible(false);
        reviewRefectch();
      }
    }
  }, [search.split('=')[1], reviewError, isAuthenticated]);

  return (
    <>
      {!review && (
        <div className='review_writing_form'>
          <p>The review link is invalid.</p>
        </div>
      )}

      {review && !review.isVisible && review.message && (
        <div className='review_writing_form'>
          <p>Your review on this booking is pending admin approval</p>
        </div>
      )}
      {review && review.isVisible && (
        <div className='review_writing_form'>
          <p>Your review on this booking is published already</p>
        </div>
      )}
      {review && !review.isVisible && !review.message && (
        <div className='review_writing_form'>
          <div className='review_writing_form_header'>
            <h2>Please share your experience of this journey</h2>

            {review.review_to.isPhotoVisible ? (
              <Avatar
                src={`${gBaseUrl}/photos/avatars/${review.review_to.photo}`}
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  height: 70,
                  width: 70,
                  marginBottom: 1,
                }}>
                {getNameAvatar(review.review_to.contact_name || 'User')}
              </Avatar>
            )}

            <p>{review?.review_to?.contact_name || 'N/A'}</p>
            <p>#{review?.review_to?.referral_code || 'N/A'}</p>
          </div>
          <div className='review_writing_form_inputs'>
            <div className='review_writing_form_inputs_top'>
              <div className='review_writing_form_inputs_top-fromto'>
                <div className='rev_tr_from'>
                  <p>From</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.pickup_location.location_name || 'N/A'}
                  </p>
                </div>
                <div className='rev_tr_to'>
                  <p>To</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.destination?.location_name || 'N/A'}
                  </p>
                </div>
              </div>
              <div className='review_writing_form_inputs_top-dater'>
                <div className='rev_tr_date'>
                  <p>Date</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.pickup_datetime
                      ? moment(trip_info.pickup_datetime).format('MMM DD, YYYY')
                      : 'N/A'}
                  </p>
                </div>
                <div className='rev_tr_reason'>
                  <p>Reason for Travel</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {formatString(trip_info?.travelling_reason) || 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            <div className='review_writing_form_inputs_bottom'>
              <div className='review_writing_form_inputs_bottom_star'>
                <Typography component='legend'>Select Rating</Typography>
                <Box
                  sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p style={{ marginTop: 3 }}>({value?.toFixed(1)})</p>
                  <Rating
                    name='hover-feedback'
                    value={value}
                    size='large'
                    icon={
                      <StarRoundedIcon
                        style={{ opacity: 1 }}
                        fontSize='inherit'
                      />
                    }
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarRoundedIcon
                        style={{ opacity: 0.55 }}
                        fontSize='inherit'
                      />
                    }
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labelsRating[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              </div>
              <div className='review_writing_form_inputs_bottom_review'>
                <textarea
                  name=''
                  id=''
                  maxLength={500}
                  cols={40}
                  value={valueTextArea}
                  onChange={(e) => {
                    setValueTextArea(e.target.value);
                  }}
                  rows={7}
                  style={{}}
                  placeholder='Write your review'></textarea>
              </div>
              <div className='review_writing_form_inputs_bottom_note'>
                <p>
                  [Please refrain from including the company's name or contact
                  information in your review. However, feel free to mention the
                  individual's name. For example, you can say, 'Jake was a good
                  driver.']
                </p>
              </div>
              <div className='review_writing_form_inputs_bottom_action'>
                <input
                  type='submit'
                  disabled={isSubmitting}
                  value={isSubmitting ? 'Please wait...' : 'Submit my review'}
                  onClick={async () => {
                    setIsSubmitting(true);
                    // Update Review
                    try {
                      const res = await updateReviewByCustomer({
                        _id: review._id,
                        review_by: user._id,
                        message: valueTextArea,
                        rating: +(value || 5),
                      });

                      alert('Your review is recorded. Thanks.');
                      setTimeout(() => {
                        navigate('/');
                      }, 2000);
                    } catch (err) {
                      console.error(err);
                    }
                    setIsSubmitting(false);
                  }}
                />
              </div>
            </div>
          </div>

          <AlertModal isOpen={isModalVisible} />

          <CustomerInfoDialog isReviewPage={true} isOpen={isAuthModalVisible} />
        </div>
      )}
    </>
  );
};

export default EnquiryReviewsWritingPage;
