import moment from 'moment';
import { formatString } from '../../utils/functions';
import './EnquiryListItem.scss';
import Colors from '../../constants/Colors';
import { TripDetailsObjType } from '../../types/schema/utils.types';

const EnquiryListItem: React.FC<{
  _id: string;
  trip_info?: TripDetailsObjType;
  proposalsCount: number;
  status: string;
  onClick: (_id: string) => void;
}> = ({ _id, trip_info, proposalsCount, status, onClick }) => {
  return (
    <div className='enquiries_item'>
      <div className='enquiries_item_content'>
        <div className='enquiries_item_pickup'>
          <div className='enquiries_item_pickup_loc'>
            <h4>Pickup</h4>
            <p>{trip_info?.pickup_location?.location_name || '--'}</p>
          </div>
          <div className='enquiries_item_pickup_status'>
            <p>{proposalsCount} Proposals</p>
          </div>
        </div>
        <div className='enquiries_item_drop'>
          <h4>Destination</h4>
          <p>{trip_info?.destination?.location_name || '--'}</p>
        </div>
        <div className='enquiries_item_pdtime'>
          <div className='enquiries_item_pdtime_pick'>
            <h4>Pickup Date</h4>
            <p>
              {trip_info?.pickup_datetime
                ? moment(trip_info.pickup_datetime).format('lll')
                : '--'}
            </p>
          </div>
          <div className='enquiries_item_pdtime_drop'>
            <h4>Return Date</h4>
            <p>
              {trip_info?.return_datetime
                ? moment(trip_info.return_datetime).format('lll')
                : '--'}
            </p>
          </div>
        </div>
        <div className='enquiries_item_pr'>
          <div className='enquiries_item_pr_p'>
            <h4>Passenger</h4>
            <p>{trip_info?.passenger_count || 0}</p>
          </div>
          <div className='enquiries_item_pr_r'>
            <h4>Reason for Travel</h4>
            <p>{formatString(trip_info?.travelling_reason) || '--'}</p>
          </div>
        </div>
      </div>

      <div className='enquiries_item_action'>
        <input
          type='submit'
          style={{
            background:
              status === 'accepting_offer'
                ? '#10356D'
                : Colors.primaryColorLight,
          }}
          value='View Details'
          onClick={() => {
            onClick(_id);
          }}
        />
      </div>
    </div>
  );
};

export default EnquiryListItem;
