import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import {
  CustomerReferralsInput,
  Referral,
} from '../../../../types/schema/referral.types';

interface GetAllReferralsByCustomerIdHook {
  data: Referral[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllReferralsByCustomerId: Referral[];
    }>
  >;
}

interface UseGetAllReferralsByCustomerIdMutationData {
  getAllReferralsByCustomerId: Referral[];
}

interface UseGetAllReferralsByCustomerIdInputType {
  input: CustomerReferralsInput;
}

export function useGetAllReferralsByCustomerId(
  query: DocumentNode,
  input: CustomerReferralsInput
): GetAllReferralsByCustomerIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    UseGetAllReferralsByCustomerIdMutationData,
    UseGetAllReferralsByCustomerIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllReferralsByCustomerId,
    loading,
    error,
    refetch,
  };
}
