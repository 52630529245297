import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import './EnquirySuccessPage.scss';
import { useNavigate } from 'react-router-dom';

// Currently Not using this page

const EnquirySuccessPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  return (
    <div className='success_note_wrapper'>
      <div className='success_note'>
        <div className='success_note_icon'>
          <CheckCircleRoundedIcon
            fontSize='large'
            style={{
              width: 60,
              height: 60,
              color: '#47C56C',
              border: '10px solid #D4F0E2',
              borderRadius: 100,
            }}
          />
        </div>
        <div className='success_note_content'>
          <h3>Your booking's successfully confirmed.</h3>

          <div className='success_note_content_message'>
            <p>
              The receipt of your deposit payment, along with the transport
              company's address and contact information has been emailed to you.
            </p>

            <br />

            <p>
              Kindly reach out to the company promptly to finalize your booking.
              Wishing you a pleasant journey, and we look forward to serving you
              again.
            </p>

            <br />

            <p>Thanks</p>
          </div>
        </div>
        <div className='success_note_action'>
          <input type='submit' value='Start new enquiry' />
        </div>
      </div>
    </div>
  );
};

export default EnquirySuccessPage;
