import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Proposal } from '../../../../types/schema/proposal.types';

interface GetAllProposalsByReferralIdHook {
  data: Proposal[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllProposalsByReferralId: Proposal[];
    }>
  >;
}

interface GetAllProposalsByReferralIdMutationData {
  getAllProposalsByReferralId: Proposal[];
}

interface GetAllProposalsByReferralIdInputType {
  input: MongoIdInput;
}

export function useGetAllProposalsByReferralId(
  query: DocumentNode,
  input: MongoIdInput
): GetAllProposalsByReferralIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllProposalsByReferralIdMutationData,
    GetAllProposalsByReferralIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllProposalsByReferralId,
    loading,
    error,
    refetch,
  };
}
