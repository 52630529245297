import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Referral,
  UpdateReferralInput,
} from '../../../../types/schema/referral.types';

const GQL_MUT = gql`
  mutation UpdateReferral($input: UpdateReferralInput!) {
    updateReferral(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateReferralMutationData {
  updateReferral: Referral;
}

interface UpdateReferralInputType {
  input: UpdateReferralInput;
}

export const useUpdateReferral = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateReferralGql, { data, error, loading }] = useMutation<
    UpdateReferralMutationData,
    UpdateReferralInputType
  >(GQL_MUT);

  const updateReferral = async (input: UpdateReferralInput) => {
    const { data, errors } = await updateReferralGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateReferral: data?.updateReferral,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateReferral, data, error, loading };
};
