import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: '7px',
    padding: 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
    '&::before': {
      backgroundColor: '#f5f5f9',
      border: '1px solid #dadde9',
    },
  },
}));

export default HtmlTooltip;
