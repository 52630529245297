/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './Footer.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';
import MapIcon from '@mui/icons-material/Map';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { URLConfig } from '../../utils/config';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { useGetAllCustomersCount } from '../../hooks/gql/query/getAll/useGetAllCustomersCount';
import { useGetAllReferralsCount } from '../../hooks/gql/query/getAll/useGetAllReferralsCount';
import { useGetAllUsersCount } from '../../hooks/gql/query/getAll/useGetAllUsersCount';

const Footer: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { data: users_count } = useGetAllUsersCount();
  const { data: referrals_count } = useGetAllReferralsCount();
  const { data: customers_count } = useGetAllCustomersCount();

  return (
    <footer className='footer_wrapper'>
      <div className='footer_content'>
        <div className='logo_content'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              minWidth: 50,
            }}>
            <img
              className='logo_desk'
              style={{
                width: 85,
                cursor: 'pointer',
              }}
              src='/images/logo_white.png'
              alt='Logo'
            />
            <img
              className='logo_mob'
              style={{
                width: 50,
                cursor: 'pointer',
              }}
              src='/images/logo_white.png'
              alt='Logo'
            />
          </div>

          <div className='privacy_terms_link'>
            <Link to={'/privacy-policy'} style={{ marginRight: 10 }}>
              Privacy Policy
            </Link>

            <Link to={'/terms-conditions'} style={{}}>
              Terms & Conditions
            </Link>
          </div>
        </div>
        <div className='actions_content'>
          <div className='action_links'>
            <div className='action_links_address'>
              <h3>COMPANY</h3>
              <div className='action_links_address_icons'>
                <MapIcon style={{ color: '#fff', marginRight: 10 }} />
                <p>20 Wenlock Road, London, England, N1 7GU</p>
              </div>

              <div className='action_links_address_icons'>
                <BusinessOutlinedIcon
                  style={{ color: '#fff', marginRight: 10 }}
                />
                <p>Registration Number: 15038249</p>
              </div>

              <div className='action_links_address_icons'>
                <CallIcon style={{ color: '#fff', marginRight: 10 }} />
                <p>0203 805 7133</p>
              </div>

              <div className='action_links_address_icons'>
                <EmailIcon style={{ color: '#fff', marginRight: 10 }} />
                <p>operations@fully-booked.uk</p>
              </div>
            </div>
            <div className='action_links_pages'>
              <h3>Pages</h3>
              <Link to={'/'} style={{}}>
                Home
              </Link>
              <Link to={'/enquires'} style={{}}>
                My Enquires
              </Link>
              <Link to={'/reviews'} style={{}}>
                Reviews
              </Link>
              <Link to={'/testimonials'} style={{}}>
                Testimonials
              </Link>
              <Link to={'/about-us'} style={{}}>
                About Us
              </Link>
            </div>
          </div>
          <div className='action_stats'>
            <div className='action_stats_numbers'>
              <div className='action_stats_numbers_item'>
                <p>{(customers_count || 0) + 1000}</p>
                <h4>Customer</h4>
              </div>

              <div className='action_stats_numbers_item'>
                <p>{(referrals_count || 0) + 2000}</p>
                <h4>Enquiry Submitted</h4>
              </div>

              <div className='action_stats_numbers_item'>
                <p>{(users_count || 0) + 500}</p>
                <h4>Registered Companies</h4>
              </div>
            </div>
            <div className='action_stats_newsletter'>
              <h4>Newsletter</h4>
              <div className='action_stats_newsletter_input'>
                <input type='email' placeholder='Enter email' required />
                <input
                  type='submit'
                  value='Subscribe'
                  onClick={(e) => {
                    //@ts-ignore
                    e.target.value = 'Please wait..';
                    setTimeout(() => {
                      //@ts-ignore
                      e.target.value = 'Subscribe';
                      alert('Email recorded Successfully!');
                    }, 500);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='copyright_content'>
          <p>&copy; {moment(new Date()).format('YYYY')} All Right Reserved</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 150,
            }}>
            <FacebookIcon
              onClick={() => {
                window.location.href = URLConfig.facebookURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
            <TwitterIcon
              onClick={() => {
                window.location.href = URLConfig.twitterURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
            <LinkedInIcon
              onClick={() => {
                window.location.href = URLConfig.linkedInURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
            <GoogleIcon
              onClick={() => {
                window.location.href = URLConfig.googleBusinessURL;
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
