import React from 'react';
import './LoadingSpinner.scss';

const LoadingSpinner: React.FC = () => {
  return (
    <div className='spinner_wrapper'>
      <div className='spinner'></div>
    </div>
  );
};

export default LoadingSpinner;
