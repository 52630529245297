import './TestimonialListItem.scss';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {
  getLabelText,
  getNameAvatar,
  getRatingLabel,
} from '../../utils/functions';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import { gBaseUrl } from '../../utils/config';

const TestimonialListItem: React.FC<{
  rating: number;
  message: string;

  added_by: {
    photo: string;
    isPhotoVisible: boolean;
    contact_name: string;
    referral_code?: string;
  };
}> = ({
  rating,
  message,
  added_by: { contact_name, referral_code, photo, isPhotoVisible },
}) => {
  return (
    <div className='testimonial_item'>
      <div className='testimonial_content'>
        <div className='title_logo'>
          <div className='logo_comp'>
            {isPhotoVisible ? (
              <Avatar
                src={`${gBaseUrl}/photos/avatars/${photo}`}
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}>
                {getNameAvatar(contact_name)}
              </Avatar>
            )}
            <div className='logo_comp_star'>
              <p className='business_name'> {contact_name}</p>
              {referral_code && <p className='refer_code'>#{referral_code}</p>}
            </div>
          </div>
          <div className='temp_status'>
            <p>{'Company'}</p>
          </div>
        </div>
        <div className='content_wrapper'>
          <p className='content_text'> {message}</p>
        </div>
      </div>
      <div className='rating_wrapper'>
        <p
          style={{
            marginRight: 10,
            marginTop: 3,
          }}>
          {rating}
        </p>
        <Box
          sx={{
            width: 200,
            display: 'flex',
            alignItems: 'center',
          }}>
          <Rating
            name='hover-review'
            value={rating}
            readOnly
            size='large'
            icon={
              <StarRoundedIcon
                style={{
                  opacity: 1,
                }}
                fontSize='inherit'
              />
            }
            precision={0.5}
            getLabelText={getLabelText}
            emptyIcon={
              <StarRoundedIcon
                style={{
                  opacity: 0.55,
                }}
                fontSize='inherit'
              />
            }
          />
          <Box
            sx={{
              ml: 2,
            }}>
            {getRatingLabel(rating)}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default TestimonialListItem;
