import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Colors from '../../constants/Colors';
import './PaymentSuccessDialog.scss';

const PaymentSuccessDialog: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}> = ({ onConfirm, onCancel, isOpen = false }) => {
  return (
    <div className='info_dialog_wrapper'>
      <Dialog
        open={isOpen}
        scroll='body'
        style={{}}
        onClose={(reason) => {
          onCancel();
        }}>
        <DialogContent>
          <DialogContentText
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              style={{ width: 80, height: 80, margin: -10 }}
              src='/images/payment_success.png'
              alt='Payment Protected'
            />
          </DialogContentText>

          <DialogTitle
            sx={{
              textAlign: 'center',
              color: Colors.primaryColorLight,
              marginTop: 1,
            }}>
            We've received your payment.
          </DialogTitle>
          <DialogContentText style={{ marginBottom: 20 }}>
            The company's contact details will be sent to you via the messaging
            window and email, along with your invoice. Please contact the
            operator at your earliest convenience. If you encounter any issues,
            use the admin support button
          </DialogContentText>

          <DialogContentText style={{ marginBottom: 20 }}>
            Thank You
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Button
            onClick={onConfirm}
            style={{
              backgroundColor: Colors.primaryColorLight,
              color: Colors.light,
            }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentSuccessDialog;
