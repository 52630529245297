import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Colors from '../../constants/Colors';
import './AlertModal.scss';
import { useNavigate } from 'react-router-dom';

const AlertModal: React.FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const navigate = useNavigate();

  return (
    <div className='info_dialog_wrapper'>
      <Dialog
        open={isOpen}
        scroll='body'
        onClose={(reason) => {
          navigate('/reviews');
        }}
        disableEscapeKeyDown>
        <DialogTitle>You can't visit this URL</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: 20 }}>
            The url you visited is not corrent. To write a review you must have
            to use the URL that is given to your email only after a booking is
            completed.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingRight: 25, paddingBottom: 20 }}>
          <Button
            onClick={() => {
              navigate('/reviews');
            }}
            style={{
              backgroundColor: Colors.primaryColorLight,
              color: Colors.light,
            }}>
            See all reviews
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertModal;
