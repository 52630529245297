import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { ReviewStatsObjType } from '../../../../types/schema/review.types';

interface GetAllReviewsStatsHook {
  data: ReviewStatsObjType | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllReviewsStats: ReviewStatsObjType;
    }>
  >;
}

interface GetAllReviewsStatsMutationData {
  getAllReviewsStats: ReviewStatsObjType;
}

export function useGetAllReviewsStats(
  query: DocumentNode
): GetAllReviewsStatsHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } =
    useQuery<GetAllReviewsStatsMutationData>(query, {
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

  return {
    data: data?.getAllReviewsStats,
    loading,
    error,
    refetch,
  };
}
