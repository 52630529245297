import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  AddEmptyReferralInput,
  Referral,
} from '../../../../types/schema/referral.types';

const GQL_MUT = gql`
  mutation AddEmptyReferral($input: AddEmptyReferralInput!) {
    addEmptyReferral(input: $input) {
      _id
      referral_token
      isActive
      pdf_ref {
        _id
        pdf_token
        pdf_uri
        pdf_filename
      }
    }
  }
`;

// Define the types for the mutation response and input
interface AddEmptyReferralMutationData {
  addEmptyReferral: Referral;
}

interface AddEmptyReferralInputType {
  input: AddEmptyReferralInput;
}

export const useAddEmptyReferral = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [addEmptyReferralGql, { data, error, loading }] = useMutation<
    AddEmptyReferralMutationData,
    AddEmptyReferralInputType
  >(GQL_MUT);

  const addEmptyReferral = async (input: AddEmptyReferralInput) => {
    const { data, errors } = await addEmptyReferralGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      addEmptyReferral: data?.addEmptyReferral,
      error: errors ? errors[0].message : null,
    };
  };

  return { addEmptyReferral, data, error, loading };
};
