import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { EmailInput } from '../../../../types/schema/utils.types';
import { Customer } from '../../../../types/schema/customer.types';

const GQL_MUT = gql`
  mutation IsCustomerEmailExists($input: EmailInput!) {
    isCustomerEmailExists(input: $input) {
      _id
      email
      name
      phone_number
    }
  }
`;

// Define the types for the mutation response and input
interface IsCustomerEmailExistsMutationData {
  isCustomerEmailExists: Customer;
}

interface IsCustomerEmailExistsInputType {
  input: EmailInput;
}

export const useIsCustomerEmailExists = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [isCustomerEmailExistsGql, { data, error, loading }] = useMutation<
    IsCustomerEmailExistsMutationData,
    IsCustomerEmailExistsInputType
  >(GQL_MUT);

  const isCustomerEmailExists = async (input: EmailInput) => {
    const { data, errors } = await isCustomerEmailExistsGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      isCustomerEmailExists: data?.isCustomerEmailExists,
      error: errors ? errors[0].message : null,
    };
  };

  return { isCustomerEmailExists, data, error, loading };
};
