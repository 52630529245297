type URLCONFIG = {
  graphQLURL: string;
  squareUpfrontPaymentSessionUrl: string;
  squareAppPaymentSessionUrl: string;

  //Social Links
  facebookURL: string;
  twitterURL: string;
  linkedInURL: string;
  googleBusinessURL: string;

  squareLocationId?: string;
  squareAppId?: string;
  mapsGeocodingAPIKey?: string;
};

export const isLive = process.env.REACT_APP_IS_LIVE === 'true';
const isLiveTestDomain = process.env.REACT_APP_LIVE_TEST_DOMAIN === 'true';
const isMobileTesting = process.env.REACT_APP_IS_MOBILE_TESTING === 'true';

const isMobileIP = false;
const isIPhone = false;
const liveDomain = isLiveTestDomain ? 'moniruzzaman.biz' : 'fully-booked.uk';

const localIPMobile1 = 'http://192.168.43.2';
const localIPMobile2 = 'http://172.20.10.2';
const localIPWiFi = 'http://192.168.0.105';

const localIPMobile = isIPhone ? localIPMobile2 : localIPMobile1;
const localIP = isMobileIP ? localIPMobile : localIPWiFi;

const localApiAddress = isMobileTesting
  ? `${localIP}:5000`
  : 'https://api.fully-booked.localhost';
const liveApiAddress = `https://api.${liveDomain}`;

export const gBaseUrl = isLive ? liveApiAddress : localApiAddress;

const squarePaymentSessionBaseUrl = isLive
  ? liveApiAddress
  : 'https://31dd-103-181-163-134.ngrok-free.app';

export const URLConfig: URLCONFIG = {
  graphQLURL: `${gBaseUrl}/graphql`,
  squareUpfrontPaymentSessionUrl: `${squarePaymentSessionBaseUrl}/payment/square_session_upfront`,
  squareAppPaymentSessionUrl: `${squarePaymentSessionBaseUrl}/payment/square_session_app`,

  // Social Links
  facebookURL: 'https://www.facebook.com/webanion/',
  twitterURL: 'https://twitter.com/webanion',
  linkedInURL: 'https://bd.linkedin.com/company/webanion',
  googleBusinessURL: 'https://www.google.com/search?q=webanion',
  mapsGeocodingAPIKey: process.env.REACT_APP_MAPS_GEOCODING_API_KEY,
  squareLocationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
  squareAppId: process.env.REACT_APP_SQUARE_APP_ID,
};
