import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import './HomePageSuccess.scss';
import { useNavigate } from 'react-router-dom';

const HomePageSuccess: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  return (
    <div className='enquiry_sent_wrapper'>
      <div className='enquiry_sent'>
        <div className='enquiry_sent_icon'>
          <CheckCircleRoundedIcon
            fontSize='large'
            style={{
              width: 60,
              height: 60,
              color: '#47C56C',
              border: '10px solid #D4F0E2',
              borderRadius: 100,
            }}
          />
        </div>
        <div className='enquiry_sent_content'>
          <h3>We've got you covered!</h3>

          <br />

          <div className='enquiry_sent_content_message'>
            <p>We've notified the best local companies of your trip.</p>

            <br />

            <p>
              You'll start to receive prices & offers very shortly. We'll email
              you every time you get a new quotation. You can also log in to 'My
              enquiry' page at any time to get an update.
            </p>
          </div>
        </div>
        <div className='enquiry_sent_action'>
          <input
            type='submit'
            value='Go to My Enquiries'
            onClick={() => {
              navigate('/enquiries');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageSuccess;
