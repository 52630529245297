import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Review,
  UpdateReviewByCustomerInput,
} from '../../../../types/schema/review.types';

const GQL_MUT = gql`
  mutation UpdateReviewByCustomer($input: UpdateReviewByCustomerInput!) {
    updateReviewByCustomer(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateReviewByCustomerMutationData {
  updateReviewByCustomer: Review;
}

interface UpdateReviewByCustomerInputType {
  input: UpdateReviewByCustomerInput;
}

export const useUpdateReviewByCustomer = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateReviewByCustomerGql, { data, error, loading }] = useMutation<
    UpdateReviewByCustomerMutationData,
    UpdateReviewByCustomerInputType
  >(GQL_MUT);

  const updateReviewByCustomer = async (input: UpdateReviewByCustomerInput) => {
    const { data, errors } = await updateReviewByCustomerGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateReviewByCustomer: data?.updateReviewByCustomer,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateReviewByCustomer, data, error, loading };
};
