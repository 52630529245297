import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Colors from '../../constants/Colors';
import './PaymentProtectionDialog.scss';

const PaymentProtectionDialog: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
  isAccepting: boolean;
  isOpen: boolean;
}> = ({ onConfirm, onCancel, isAccepting, isOpen = false }) => {
  return (
    <div className='info_dialog_wrapper'>
      <Dialog
        open={isOpen}
        scroll='body'
        style={{}}
        onClose={(reason) => {
          onCancel();
        }}>
        <DialogContent>
          <DialogContentText
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              style={{ width: 120, height: 120, margin: -10 }}
              src='/images/payment_protected.png'
              alt='Payment Protected'
            />
          </DialogContentText>

          <DialogTitle
            sx={{
              textAlign: 'center',
              color: Colors.primaryColorLight,
              paddingTop: 0,
            }}>
            Your Payment Is Protected
          </DialogTitle>
          <DialogContentText style={{ marginBottom: 20 }}>
            We won’t touch your payment until your trip goes ahead. Once your
            trip is completed the deposit payment will be sent to the relevant
            operator.
          </DialogContentText>

          <DialogContentText style={{ marginBottom: 20, fontWeight: 'bold' }}>
            In the very unlikely chance that your booking doesn’t go ahead. You
            will be refunded instantly. No Questions.
          </DialogContentText>

          <DialogContentText style={{ marginBottom: 20 }}>
            Why book this operator on our platform?
          </DialogContentText>

          <DialogContentText style={{ marginBottom: 20 }}>
            If a problem were to occur with the operator you booked with, we can
            put out a SOS to every company on our platform to cover you.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingBottom: 15,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Button
            onClick={onCancel}
            style={{
              backgroundColor: Colors.accentColorDark,
              color: Colors.light,
              marginRight: 20,
            }}>
            Cancel
          </Button>

          <Button
            onClick={onConfirm}
            style={{
              backgroundColor: Colors.primaryColorLight,
              color: Colors.light,
            }}>
            {isAccepting ? 'Please Wait...' : 'Confirm Payment'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentProtectionDialog;
