/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './PaymentPageApp.scss';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import axios from 'axios';
import { URLConfig } from '../../utils/config';
import { formatString, getAmountWithCurrency } from '../../utils/functions';

const PaymentPageApp: React.FC<{}> = (props) => {
  const [isPaying, setIsPaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [amount, setAmount] = useState('');
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(search);
  const user_id = queryParams.get('user_id');
  const given_amount = queryParams.get('amount');

  useEffect(() => {
    if (user_id) {
      setUserId(userId);
    }
    if (given_amount) {
      setAmount(given_amount);
    }
  }, [user_id, given_amount]);

  return (
    <div className='payment_page_app_content_wrapper'>
      <div className='payment_page_app_section'>
        <div className='payment_page_app_section_ad'>
          <h1>Confirm Your Payment</h1>
          <p className='payment_page_app_section_ad-location'>
            You are going to pay
          </p>
          <p className='payment_page_app_section_ad-amount'>
            {getAmountWithCurrency(+amount)}
          </p>
          <p className='payment_page_app_section_ad-amount_note'>
            Please enter your card information to pay us
          </p>

          <br />
        </div>
        <div className='payment_page_app_section_form'>
          {errorMessage && (
            <div style={{ marginBottom: 10 }}>
              <p style={{ color: 'red' }}>{errorMessage}</p>
            </div>
          )}

          <PaymentForm
            applicationId={URLConfig.squareAppId!}
            cardTokenizeResponseReceived={async (token) => {
              setErrorMessage('');
              setIsPaying(true);

              try {
                await axios({
                  method: 'POST',
                  url: URLConfig.squareAppPaymentSessionUrl,
                  headers: {
                    'Content-type': 'application/json',
                  },
                  data: {
                    sourceId: token.token,
                    operatorId: user_id,
                    amount: +amount,
                  },
                });

                setErrorMessage('');
                setIsPaying(false);
                const schemeUrl = `fbookd://myearningsmywallet`;
                //@ts-ignore
                window.ReactNativeWebView.postMessage('Payment Successful');
                window.location.href = schemeUrl;
              } catch (err: any) {
                setIsPaying(false);
                setErrorMessage(
                  formatString(err?.response?.data?.error?.errors[0]?.detail) ||
                    'Payment failed! Please contact support.'
                );
              }
            }}
            locationId={URLConfig.squareLocationId!}>
            <CreditCard
              render={(Button: any) => (
                <Button
                  isLoading={isPaying}
                  style={{
                    fontFamily: 'DM Sans',
                    backgroundColor: isPaying ? '#ddd' : '#47C56C',
                    fontSize: '17px',
                    color: '#fff',
                  }}>
                  {isPaying
                    ? 'Please wait...'
                    : `Pay 
                  ${getAmountWithCurrency(+amount)}`}
                </Button>
              )}
            />
          </PaymentForm>
        </div>
      </div>
    </div>
  );
};

export default PaymentPageApp;
